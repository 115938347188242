import { get, post } from '../treasury';


// 创建 businessCard schema
export const createSchemas = (params) => post(`api/v1/businessCard/schema/create`, params)

// 查询 businessCard schema
export const querySchemas = (params) => post(`api/v1/businessCard/schema/querySchemas`, params)

// 查询 VC Schemas
export const queryVCSchemas = (params) => get("api/v1/vc/schema/queryVCSchemas", params)
