import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
// 防止路由重复点击报错
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};
const routes = [{
  path: '/',
  redirect: '/login'
},
{
  path: '/Login',
  name: 'Login',
  component: () => import( /* webpackChunkName: "login" */ '../views/login.vue'),
},
{
  path: '/page',
  name: 'page',
  component: () => import( /* webpackChunkName: "login" */ '../views/page.vue'),
  children: [
    {
      path: '/userManagement',
      component: () => import('../views/userManagement/index')
    },
    {
      path: '/userManagement/details',
      meta: {
        breadcrumb: 'User Management/User Details',
      },
      component: () => import('../views/userManagement/details')
    },
    {
      path: '/verifiable',
      component: () => import('../views/verifiable')
    },
    {
      path: '/businessCard',
      component: () => import('@/views/businessCard'),
    }
  ]
},
]

const router = new VueRouter({
  routes
})

export default router
