import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import ElementUI, {
  Table
} from 'element-ui';
import '../element-variables.scss';
import config from './utils/config.js'
import * as api from './utils/api/index.js'
import debounce from './utils/debounce.js'
import './style/global.css';

// import conversionUSD from './utils/countUSD.js'

Vue.prototype.$api = api
Vue.prototype.$http = axios

Vue.use(config)
Vue.prototype.$debounce = debounce

function toFixedFloor(num, decimal = 6) {
  if (num != 0) {
    return (Math.floor(num * Math.pow(10, decimal)) / Math.pow(10, decimal)).toFixed(decimal)
  }
  return 0
}
Vue.prototype.toFixedFloor = toFixedFloor
// Vue.prototype.conversionUSD = conversionUSD

import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

Vue.config.productionTip = false
Vue.use(ElementUI, {
  size: 'small',
  locale: {
    el: {
      // 整体覆盖
      ...zhLocale.el,
      pagination: {
        pagesize: '/page',
        total: `Total {total} items`,
        goto: 'Go to',
        pageClassifier: ''
      },
    }
  }
});

Vue.directive('permission', {
  inserted(el, bind) {
    let flowList = JSON.parse(localStorage.getItem('flowList')) || []
    let data = bind.value[0]
    el.style = "display:none"
    if (flowList.length > 0) {
      if (flowList.some(item => data.includes(item))) {
        el.style = "display:true"
      }
    }
  }
})
Vue.prototype.permission = function (arr) {
  let mode = false
  let flowList = JSON.parse(localStorage.getItem('flowList')) || []
  if (flowList.length > 0) {
    if (flowList.some(item => arr.includes(item))) {
      mode = true
    }
  }
  return mode
}

/**计算列内容最大宽度
 * 遍历列的所有内容，获取最宽一列的宽度
 * @param arr
 */
Vue.prototype.getMaxLength = function (arr) {
  return arr.reduce((acc, item) => {
    if (item) {
      const calcLen = this.getTextWidth(item)
      if (acc < calcLen) {
        acc = calcLen
      }
    }
    return acc
  }, 0)
}
/**
 * 使用span标签包裹内容，然后计算span的宽度 width： px
 * @param valArr
 */
Vue.prototype.getTextWidth = function (str) {
  let width = 0
  const html = document.createElement('span')
  html.innerText = str
  html.className = 'getTextWidth'
  document.querySelector('body').appendChild(html)
  width = document.querySelector('.getTextWidth').offsetWidth
  document.querySelector('.getTextWidth').remove()
  return width
}
// 解决 ElTable 自动宽度高度导致的「ResizeObserver loop limit exceeded」问题
const fixElTableErr = (table) => {
  const oldResizeListener = table.methods.resizeListener;
  table.methods.resizeListener = function () {
    window.requestAnimationFrame(oldResizeListener.bind(this));
  };
};
// 一定要在Vue.use之前执行此函数
fixElTableErr(Table);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')